import { template as template_8522ec9c05d049118f41a9d37fc0cd68 } from "@ember/template-compiler";
const WelcomeHeader = template_8522ec9c05d049118f41a9d37fc0cd68(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
