import { template as template_a24b479ece914a63bc1c5ca771b4b42c } from "@ember/template-compiler";
const SidebarSectionMessage = template_a24b479ece914a63bc1c5ca771b4b42c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
